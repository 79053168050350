// src/dropcursor.ts
import { Extension } from "@tiptap/core";
import { dropCursor } from "@tiptap/pm/dropcursor";
var Dropcursor = Extension.create({
  name: "dropCursor",
  addOptions() {
    return {
      color: "currentColor",
      width: 1,
      class: void 0
    };
  },
  addProseMirrorPlugins() {
    return [
      dropCursor(this.options)
    ];
  }
});

// src/index.ts
var src_default = Dropcursor;
export {
  Dropcursor,
  src_default as default
};
